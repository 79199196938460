<template>
  <el-dialog
    v-elDragDialog
    width="80%"
    v-loading="loading"
    :title="$t('serviceRoleManagement.assignPermiss')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-service"
  >
    <div class="header">
      <div class="left">
        <span>{{ $t('serviceRoleManagement.roleName') }}：</span>
        <span>{{ roleName }}</span>
      </div>

      <div v-if="authList.length" class="right">
        <el-checkbox v-model="isAll" @change="selectAll">{{
          $t('serviceRoleManagement.allPermiss')
        }}</el-checkbox>
      </div>
    </div>

    <div class="table-wrap" :class="{ 'en-table-wrap': $i18n.locale == 'en' }">
      <div class="table">
        <div class="tr">
          <th>{{ $t('serviceRoleManagement.modul') }}</th>
          <th>{{ $t('serviceRoleManagement.function') }}</th>
        </div>
        <div class="tr" v-for="item in authList" :key="item.resourceId">
          <div class="td">
            <el-checkbox
              @change="
                val => {
                  checkChange(val, item)
                }
              "
              v-model="item.checked"
              >{{ $t('basicsData.' + item.label) }}</el-checkbox
            >
          </div>
          <div class="col-wrap">
            <div class="col" v-for="subItem in item.children" :key="subItem.resourceId">
              <div class="td">
                <el-checkbox
                  v-model="subItem.checked"
                  @change="val => checkChange(val, subItem, item.children)"
                  >{{ $t('basicsData.' + subItem.label) }}</el-checkbox
                >
              </div>
              <div class="td" :key="subItem.resourceId">
                <el-checkbox
                  v-for="subSubItem in subItem.children"
                  @change="val => checkChange(val, subSubItem, subItem.children, item.children)"
                  v-model="subSubItem.checked"
                  :key="subSubItem.resourceId"
                  :label="subSubItem.label"
                  >{{ $t('basicsData.' + subSubItem.label) }}</el-checkbox
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span v-if="authList.length" slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
      loading: false,
      isAll: false,
      roleName: '',
      authList: [],
      originalList: []
    }
  },
  async created() {
    console.log('this.$i18n.locale')
    console.log(this.$i18n.locale)
    this.roleName = this.currentItem.roleName || ''
    try {
      this.loading = true
      await this.init()
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  },
  methods: {
    async init() {
      try {
        const res = await this.$api.systemManagement.resourceAll({
          system: this.currentItem.system
        })
        let list = res.result
          .filter(_ => _.resourceName && _.resourceDesc)
          .sort((a, b) => a.id - b.id)

        list.forEach(item => {
          item.label = item.resourceDesc
          item.checked = false
        })
        this.originalList = [...list]

        list = await this.getData(list)

        this.isAll = list.every(_ => _.checked)

        this.initList(list)
      } catch (err) {
      } finally {
        return Promise.resolve()
      }
    },
    async getData(authList) {
      try {
        const res = await this.$api.systemManagement.queryUserRoles([this.currentItem.roleId])

        const list = res.result.list || res.result
        list.forEach(item => {
          const e = authList.find(e => e.resourceId === item.resourceId)
          if (e) {
            e.checked = true
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        return Promise.resolve(authList)
      }
    },
    initList(list) {
      const digui = item => {
        item.children = list.filter(sub => {
          return item.resourceId === sub.parentId
        })

        if (item.children.length) {
          item.children.forEach(sub => {
            digui(sub)
          })
        }
        return item
      }

      const parent = [...list].filter(item => !item.parentId)
      this.authList = parent.map(digui)
    },
    recursionParent(val, item, currentCheckList, parentCheckList) {
      const recursionParent = (target, list) => {
        const parent = this.originalList.find(_ => _.resourceId === target.parentId)

        if (parent) {
          if (list.map(_ => _.checked).includes(true)) {
            parent.checked = true
          } else {
            parent.checked = false
          }

          if (parent.parentId) {
            recursionParent(parent, parentCheckList)
          }
        }

        return
      }

      if (item.parentId) {
        recursionParent(item, currentCheckList)
      }
    },
    recursionChildren(val, item) {
      if (!item.children.length) return

      const recursionChildren = item => {
        item.checked = val

        if (item.children.length) {
          item.children.forEach(_ => {
            recursionChildren(_)
          })
        }
      }

      item.children.forEach(_ => {
        recursionChildren(_)
      })
    },
    checkChange(val, item, currentList, parentCheckList) {
      this.recursionParent(val, item, currentList, parentCheckList)
      this.recursionChildren(val, item, currentList)
      this.isAll = this.originalList.every(_ => _.checked)
    },
    selectAll(val) {
      this.originalList.forEach(_ => {
        _.checked = val
      })

      this.initList(this.originalList)
    },
    async submitForm() {
      try {
        this.loading = true
        const resourceIds = []
        const digui = item => {
          item.checked && resourceIds.push(item.resourceId)
          if (item.children) {
            item.children.forEach(digui)
          }
        }
        this.authList.map(digui)

        await this.$api.systemManagement.permissionAssign({
          resourceIds,
          roleId: this.currentItem.roleId
        })

        this.$message.success(this.$t('saveSuccess'))
        this.$emit('getData')
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
        this.show = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-checkbox {
  margin-top: 5px;
  color: #000;
}
.no-data {
  flex: 1 !important;
  span {
    line-height: 19px;
    font-size: 14px;
    margin: 7px 0 3px 0;
  }
}
.table-wrap {
  margin-top: 20px;
  display: flex;
  height: 500px;
  flex: 1;
  overflow: auto;
  width: 100%;
  flex-direction: column;
  * {
    box-sizing: border-box;
  }
  .table {
    .tr {
      overflow: hidden;
      display: flex;

      th {
        font-size: 14px;
        border: 1px solid #ececec;
        color: #999999;
        padding: 20px 0;
        text-align: center;
        &:nth-child(1) {
          width: 140px;
          float: left;
        }
        &:nth-child(2) {
          width: calc(100% - 140px);
          flex-basis: calc(100% - 140px);
          min-width: calc(100% - 140px);
          border-left: 0;
          float: left;
        }
      }
      .td {
        border-right: 1px solid #ececec;
        border-bottom: 1px solid #ececec;
        font-size: 14px;
        padding: 10px 0;
        color: #000;
        &:nth-child(1) {
          border-left: 1px solid #ececec;
          border-right: 0;
          width: 139px;
          flex: initial;
          display: flex;
          align-items: center;
          padding-left: 10px;
          // justify-content: center;
        }
        & + .td {
          border-left: 0;
        }
      }
      .col-wrap {
        flex: 1;
        .col {
          display: flex;
          .td {
            border-right: 1px solid #ececec;
            &:nth-child(1) {
              width: 180px;
              flex-shrink: 0;
            }
            &:nth-child(2) {
              display: block;
              width: calc(100% - 180px);
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
}
.en-table-wrap {
  .table {
    .tr {
      th {
        &:nth-child(1) {
          width: 260px;
        }
        &:nth-child(2) {
          width: calc(100% - 260px);
          flex-basis: calc(100% - 260px);
          min-width: calc(100% - 260px);
        }
      }
      .td {
        &:nth-child(1) {
          width: 260px;
        }
      }
      .col-wrap {
        .col {
          .td {
            &:nth-child(1) {
              width: 220px;
            }
            &:nth-child(2) {
              width: calc(100% - 220px);
            }
          }
        }
      }
    }
  }
}
</style>
