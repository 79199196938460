<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('serviceRoleManagement.roleName')"
        v-model.trim="params.roleName"
        @keyup.enter.native="replaceData"
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
      <el-button v-show="multipleSelection.length" type="danger" plain @click="delHandler(true)">{{
        $t('delete')
      }}</el-button>
    </div>
    <div slot="headerRight">
      <el-button
        v-if="btnShow && btnShow.add"
        type="primary"
        @click="
          visible = true
          currentItem = null
        "
        icon="el-icon-plus"
        >{{ $t('newadd') }}</el-button
      >
    </div>

    <div class="table-wrap">
      <el-table
        :span-method="objectSpanMethod"
        @selection-change="handleSelectionChange"
        :data="tableData"
        border
        v-loading="loading"
        height="100%"
      >
        <el-table-column v-if="btnShow && btnShow.delete" type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip
          prop="spId"
          :label="$t('serviceManagement.customerID')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="roleName"
          :label="$t('serviceRoleManagement.roleName')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="roleDesc"
          :label="$t('serviceRoleManagement.roleInfo')"
        />

        <el-table-column show-overflow-tooltip :label="$t('deviceList.createdTime')">
          <template slot-scope="{ row }">
            {{ $utils.formatTime(row.createTime) }}
          </template>
        </el-table-column>

        <el-table-column width="100" :label="$t('activeDevice.state')">
          <template slot-scope="{ row }">
            <el-switch :disabled="row.isChild" v-model="row.status" @change="roleUpdate(row)" />
          </template>
        </el-table-column>

        <el-table-column width="220" :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button
                v-if="btnShow && btnShow.assign"
                type="text"
                @click="
                  currentItem = row
                  assignAuthorityVisible = true
                "
                >{{ $t('serviceRoleManagement.assignPermiss') }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.edit && row.remark != 'default'"
                type="text"
                @click="editHandler(row)"
                >{{ $t('edit') }}</el-button
              >
              <el-button
                v-if="btnShow && btnShow.delete && row.remark != 'default'"
                type="text"
                @click="delHandler(false, row.roleId)"
                >{{ $t('delete') }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <div style="text-align:right">
      <el-pagination :total="total" layout="total" />
    </div> -->
    <!-- <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    /> -->
    <add-role-modal
      @getData="replaceData"
      :currentItem="currentItem"
      :serviceList="serviceList"
      :visible.sync="visible"
      v-if="visible"
    />
    <assign-authority-modal
      @getData="replaceData"
      :currentItem="currentItem"
      :visible.sync="assignAuthorityVisible"
      v-if="assignAuthorityVisible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddRoleModal from './components/AddRoleModal'
import AssignAuthorityModal from './components/AssignAuthorityModal'

export default {
  mixins: [watchParamsGetData],
  components: {
    AddRoleModal,
    AssignAuthorityModal
  },
  data() {
    return {
      loading: false,
      visible: false,
      currentItem: null,
      tableData: [],
      multipleSelection: [],
      serviceList: [],
      assignAuthorityVisible: false
    }
  },
  created() {
    this.getSpList()
    console.log('333,', this.btnShow)
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 1) {
        if (row.parentId) {
          return {
            rowspan: row.len,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    async getSpList() {
      const res = await this.$api.systemManagement.spList({ pageSize: -1 })
      this.serviceList = ((res.result && res.result.list) || []).map(item => {
        return {
          label: item.name,
          value: item.spId
        }
      })
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const data = this.params
        data.pageSize = -1
        data.system = 2
        const res = await this.$api.systemManagement.roleList(data)

        if (res.result) {
          const parentIds = []

          const spIdCount = res.result.list.reduce((obj, item) => {
            obj[item.spId] = obj[item.spId] ? ++obj[item.spId] : 1

            return obj
          }, {})

          this.tableData = (res.result.list.sort((a, b) => a.spId - b.spId) || []).map(item => {
            if (!parentIds.includes(item.spId)) {
              parentIds.push(item.spId)
              item.parentId = item.spId
              item.len = spIdCount[item.spId]
            } else {
              item.isChild = true
            }

            item.status = !item.status
            return item
          })

          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.parentId)
    },

    editHandler(row) {
      this.currentItem = row
      this.visible = true
    },

    delHandler(delMore, roleId) {
      this.$confirm(
        delMore
          ? this.$t('serviceRoleManagement.deleteSelectedRole')
          : this.$t('serviceRoleManagement.deleteThisRole'),
        this.$t('tips'),
        {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel')
        }
      )
        .then(async () => {
          // this.loading = true

          let roleDeleteIds = []

          this.multipleSelection.forEach(spId => {
            const roleIds = this.tableData.filter(target => target.spId === spId).map(_ => _.roleId)
            roleDeleteIds = [...roleDeleteIds, ...roleIds]
          })

          try {
            await this.$api.systemManagement.roleDel(delMore ? roleDeleteIds : [roleId])
            this.$message.success(this.$t('deleteSuccess'))
            this.getData(false)
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        })
        .catch(() => {})
    },

    async roleUpdate(row) {
      this.loading = true
      try {
        await this.$api.systemManagement.roleUpdate({
          roleId: row.roleId,
          status: row.status ? 0 : 1
        })
      } catch (err) {
        row.status = !row.status
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
