<template>
  <el-dialog v-loading="loading" v-elDragDialog :close-on-click-modal="false" :visible.sync="show">
    <page class="device-model">
      <div slot="headerLeft">
        <el-form inline>
          <el-form-item :label="$t('deviceList.sn')">
            <el-input :placeholder="$t('deviceList.sn')" v-model.trim="params.sn" clearable />
          </el-form-item>
          <el-button type="primary" plain @click="listDevice">
            {{ $t('query') }}
          </el-button>
        </el-form>
      </div>
      <el-table
        ref="table"
        :data="tableData"
        class="table"
        border
        row-key="id"
        height="100%"
        v-loading="loading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :reserve-selection="true" width="55" />
        <el-table-column show-overflow-tooltip prop="sn" :label="$t('deviceList.sn')" />
        <el-table-column
          show-overflow-tooltip
          prop="modelName"
          :label="$t('deviceList.deviceType')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="groupName"
          :label="$t('deviceList.deviceGroup')"
        />
        <el-table-column show-overflow-tooltip prop="store" :label="$t('deviceList.store')" />
      </el-table>
      <pagination
        slot="pagination"
        :total="total"
        :page-size.sync="params.pageSize"
        :page.sync="params.currentPage"
        @change="listDevice"
      />
    </page>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      params: {
        sn: '',
        pageSize: 20,
        currentPage: 1
      },
      total: 0,
      tableData: [],
      selected: []
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.listDevice()
  },
  methods: {
    async listDevice() {
      try {
        const res = await this.$api.deviceManagement.deviceList(this.params)
        if (res.result) {
          this.tableData = res.result.list.map(item => {
            if (item.groups && item.groups.length > 0) {
              item.groupName = item.groups[0].name
            }
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleSelectionChange(val) {
      this.selected = val
    },
    async submitForm(formData) {
      if (this.selected.length > 0) {
        const selected = this.selected.map(item => {
          return {
            sn: item.sn,
            modelName: item.modelName,
            groupName: item.groupName,
            store: item.store
          }
        })
        this.$emit('selected', selected)
      }
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.device-model {
  height: 500px;
}
</style>
