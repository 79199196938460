<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="700px"
    :title="$t('customerStatistical.warningConfig')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="alertConfig"
  >
    <div class="main">
      <el-card class="box-card">
        <div slot="header">
          <span>{{$t('customerStatistical.CPUHeatWarning')}}</span>
          <el-switch v-model="tempSwitch" />
        </div>
        <div class="sliderWrap">
          <el-slider
            :disabled="!tempSwitch"
            :format-tooltip="tempTooltip"
            :marks="tempMark"
            v-model="form.temperature"
          />
        </div>
        <div class="tips">
          {{$t('customerStatistical.CPUHeatTips')}}
        </div>
      </el-card>

      <div class="bottomCot">
        <el-card style="margin-right:20px" class="box-card">
          <div slot="header">
            <span>{{$t('customerStatistical.runMemoryWarning')}}</span>
            <el-switch v-model="memorySwitch" />
          </div>
          <div style="text-align:center;">
            <el-progress
              :format="format"
              type="dashboard"
              :percentage="form.memory"
              :color="colors"
            />
            <div>
              <el-input-number
                :disabled="!memorySwitch"
                :min="0"
                :max="100"
                size="small"
                v-model="form.memory"
                @blur="blurHandler(form.memory, 'memory')"
              />
            </div>
          </div>

          <div class="tips">
            {{$t('customerStatistical.occupiedMemoryTips')}}
          </div>
        </el-card>

        <el-card class="box-card">
          <div slot="header">
            <span>{{$t('customerStatistical.storageWarning')}}</span>
            <el-switch v-model="storageSwitch" />
          </div>
          <div style="text-align:center;">
            <el-progress
              :format="format"
              type="dashboard"
              :percentage="form.storage"
              :color="colors"
            />
            <div>
              <el-input-number
                :disabled="!storageSwitch"
                :min="0"
                :max="100"
                size="small"
                v-model="form.storage"
                @blur="blurHandler(form.storage, 'storage')"
              />
            </div>
          </div>

          <div class="tips">
            {{$t('customerStatistical.setProportionWarning')}}
          </div>
        </el-card>
      </div>
	  <el-card class="box-card" style="margin-top: 20px;">
	    <div slot="header">
	  		<span>{{$t('customerStatistical.PeripheralExceptionWarning')}}</span>
	  		<el-switch v-model="peripheralSwitch" />
	    </div>
		<div class="tips">
		  {{$t('customerStatistical.PeripheralExceptionTips')}}
		</div>
	  </el-card>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{$t('cancel')}}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{$t('save')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  data() {
    return {
	  peripheralSwitch: true,
      tempSwitch: true,
      memorySwitch: true,
      storageSwitch: true,

      loading: false,

      colors: [
        { color: '#f56c6c', percentage: 100 },
        { color: '#e6a23c', percentage: 80 },
        { color: '#1989fa', percentage: 60 },
        { color: '#1989fa', percentage: 40 },
        { color: '#5cb87a', percentage: 20 }
      ],

      sendData: [
        {
          warningType: 1,
          warningSwitch: true,
          warningThreshold: '0'
        },
        {
          warningType: 2,
          warningSwitch: true,
          warningThreshold: '0'
        },
        {
          warningType: 3,
          warningSwitch: true,
          warningThreshold: '0'
        },
        {
          warningType: 4,
          warningSwitch: true
        }
      ],

      tempMark: {
        0: '0℃',
        100: '100℃'
      },

      form: {
        temperature: 0,
        memory: 0,
        storage: 0
      },
      firstId: '',
      secondId: '',
      thirdId: '',
	  fourId: ''
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    blurHandler(val, type) {
      if (val > 100 || val < 0) return
      this.form[type] = +val.toFixed(2)
    },
    async getConfig() {
      try {
        const res = await this.$api.systemManagement.warnConfig()
        if (res && res.result && res.result.length) {
          res.result.forEach(item => {
            if (item.warningType === 1) {
              this.firstId = item.id
              this.form.temperature = +item.warningThreshold
              this.tempSwitch = !!item.warningSwitch
            }

            if (item.warningType === 2) {
              this.secondId = item.id
              this.form.memory = +item.warningThreshold
              this.memorySwitch = !!item.warningSwitch
            }

            if (item.warningType === 3) {
              this.thirdId = item.id
              this.form.storage = +item.warningThreshold
              this.storageSwitch = !!item.warningSwitch
            }
			if (item.warningType === 4) {
			  this.fourId = item.id
			  this.peripheralSwitch = !!item.warningSwitch
			}
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    format(percentage) {
      return `${this.$t('customerStatistical.use')}${percentage}%`
    },
    increase(type) {
      this.form[type] += 10
      if (this.form[type] > 100) {
        this.form[type] = 100
      }
    },
    decrease(type) {
      this.form[type] -= 10
      if (this.form[type] < 0) {
        this.form[type] = 0
      }
    },
    tempTooltip(val) {
      return val + '℃'
    },
    percentTooltip(val) {
      return val + '%'
    },
    async submitForm(formData) {
      const sendData = this.sendData

      if (this.firstId) {
        sendData[0].id = this.firstId
      }

      if (this.secondId) {
        sendData[1].id = this.secondId
      }

      if (this.thirdId) {
        sendData[2].id = this.thirdId
      }
      if (this.fourId) {
        sendData[3].id = this.fourId
      }

      sendData[0].warningThreshold = formData.temperature
      sendData[0].warningSwitch = this.tempSwitch ? 1 : 0
      sendData[1].warningThreshold = formData.memory
      sendData[1].warningSwitch = this.memorySwitch ? 1 : 0
      sendData[2].warningThreshold = formData.storage
      sendData[2].warningSwitch = this.storageSwitch ? 1 : 0
	  sendData[3].warningSwitch = this.peripheralSwitch ? 1 : 0

      this.loading = true
      try {
        const res = await this.$api.systemManagement.warnConfigUpdate({
          deviceWarningConfigReqVoList: sendData
        })
        this.$message.success(this.$t('saveSuccess'))
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alertConfig {
  .el-card__header > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
  }

  ::v-deep .sliderWrap {
    width: 80%;
    margin: 0 auto;
    .el-slider__marks-text {
      width: 40px;
    }

    .el-slider__bar {
      background-image: linear-gradient(to right, #f17503, #de1c15);
    }

    .el-slider__button {
      border: 2px solid #e4390f;
    }

    .el-slider__runway.disabled .el-slider__bar {
      background-image: none;
    }

    .el-slider__runway.disabled .el-slider__button {
      border: 2px solid #c0c4cc;
    }

    .el-slider__stop {
      height: 0;
    }
  }

  .tips {
    text-align: center;
    margin-top: 30px;
    color: #666;
  }

  .bottomCot {
    display: flex;
    margin-top: 20px;
    .el-card {
      flex: 1;
    }

    ::v-deep .el-progress__text {
      font-size: 14px !important;
    }
  }
}
</style>
