<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="500px"
    :title="$t('tips')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="validDateModal"
  >
    <el-form size="small" ref="form" label-width="100px">
      <el-date-picker v-model="validDate" type="date" :placeholder="$t('serviceManagement.selectValidPeriod')" />
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{$t('cancel')}}</el-button>
      <el-button type="primary" @click="submitForm()">{{$t('save')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['groupIds'],
  components: {},
  data() {
    return {
      validDate: ''
    }
  },
  created() {},
  methods: {
    changeHandler(val) {
      this.validDate = ''
    },
    async submitForm(data) {
      if (!this.validDate) {
        this.$message.warning(this.$t('serviceManagement.selectValidPeriod'))
        return
      }

      this.loading = true

      const params = {
        parameter3: this.$utils.formatTime(this.validDate),
        type: 8
      }

      if (this.groupIds) {
        params.groupIds = this.groupIds.join(',')
      } else {
        params.deviceSn = this.currentItem.sn
      }

      try {
        const res = await this.$api.deviceManagement.publishRemoteAssistance(params)

        this.$message.success(this.$t('settingSuccess.deviceModal'))
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 193px;
}
</style>
