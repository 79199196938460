<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="700px"
    :title="isCreated ? $t('serviceRoleManagement.newRole') : $t('serviceRoleManagement.editRole')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-service"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
      <el-form-item prop="roleName" :label="$t('serviceRoleManagement.roleName')">
        <el-input v-model.trim="form.roleName" :placeholder="$t('serviceRoleManagement.enterRoleName')" />
      </el-form-item>

      <!-- <el-form-item label="状态" prop="roleDesc">
        <el-switch
          :disabled="isCreated"
          v-model="form.status"
          active-text="启用"
          inactive-text="停用"
        />
      </el-form-item> -->

      <el-form-item :label="$t('deviceList.customer')" prop="spId">
        <el-select v-model="form.spId" :placeholder="$t('serviceAccountManagement.pleaseSelectCustomer')">
          <el-option
            v-for="item in serviceList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="所属系统" prop="type">
        <el-radio value="2" label="2">客户平台</el-radio>
      </el-form-item> -->

      <el-form-item :label="$t('serviceRoleManagement.roleInfo')" prop="roleDesc">
        <el-input
          type="textarea"
          rows="4"
          resize="none"
          v-model="form.roleDesc"
          :placeholder="$t('serviceRoleManagement.enterRoleInfo')"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{$t('cancel')}}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{$t('save')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['serviceList'],
  components: {},
  data() {
    return {
      loading: false,
      form: {
        roleName: '',
        roleDesc: '',
        status: true,
        system: 2,
        spId: ''
      },
      rules: {
        roleName: [{ required: true, message: this.$t('serviceRoleManagement.enterRoleName') }]
      }
    }
  },
  created() {
    if (!this.isCreated) {
      this.form = { ...this.currentItem }
    }
  },
  methods: {
    submitForm(data) {
      const sendData = { ...data }
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            sendData.status = sendData.status ? 0 : 1

            // if (this.isCreated) {
            //   sendData.roleId = this.currentItem.roleId
            // }

            this.isCreated
              ? await this.$api.systemManagement.roleAdd(sendData)
              : await this.$api.systemManagement.roleUpdate(sendData)
            this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess'))
            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// .add-service {
//   ::v-deep .el-form {
//     width: 400px;
//     margin: 0 auto;
//   }
// }
</style>
