import { render, staticRenderFns } from "./AddRelateModal.vue?vue&type=template&id=2865f57c&scoped=true"
import script from "./AddRelateModal.vue?vue&type=script&lang=js"
export * from "./AddRelateModal.vue?vue&type=script&lang=js"
import style0 from "./AddRelateModal.vue?vue&type=style&index=0&id=2865f57c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2865f57c",
  null
  
)

export default component.exports