<template>
  <el-dialog
    v-elDragDialog
    width="500px"
    :title="$t('changePassword')"
    :close-on-click-modal="false"
    :visible.sync="show"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
      <el-form-item :label="$t('newPassword')" prop="password">
        <el-input
          :type="invisibleOne ? 'password' : 'text'"
          :placeholder="$t('pleaseEnter')"
          v-model.trim="form.password"
        >
          <icon-svg
            v-if="form.password"
            :icon-class="invisibleOne ? 'iconbukejian' : 'iconkejian'"
            slot="suffix"
            @click="invisibleOne = !invisibleOne"
          />
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('confirmationNewPassword')" prop="confirmPassword">
        <el-input
          @keyup.native.enter="submitForm"
          :type="invisibleTwo ? 'password' : 'text'"
          :placeholder="$t('pleaseEnter')"
          v-model.trim="form.confirmPassword"
        >
          <icon-svg
            v-if="form.confirmPassword"
            :icon-class="invisibleTwo ? 'iconbukejian' : 'iconkejian'"
            slot="suffix"
            @click="invisibleTwo = !invisibleTwo"
          />
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm" v-loading="loading">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  data() {
    const passwordValid = (rule, value, callback) => {
      const pdTest = /^(?=.*[~!@#$%^&*()_?])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/

      if (value === '') {
        callback(new Error(this.$t('inputNewPassword')))
      } else {
        if (value === this.currentItem.accountCode) {
          callback(new Error(this.$t('passwordDifferentAccount')))
        } else if (!pdTest.test(value)) {
          callback(new Error(this.$t('passwordRules')))
        } else {
          callback()
        }
      }
    }

    const passwordConfirmValid = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('serviceAccountManagement.enterConfirmPassword')))
      } else if (value !== this.form.password) {
        callback(new Error(this.$t('twicePasswordDifferent')))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      invisibleOne: true,
      invisibleTwo: true,
      roleList: [],
      form: {
        password: '',
        confirmPassword: ''
      },
      rules: {
        password: [{ validator: passwordValid, trigger: 'blur', required: true }],
        confirmPassword: [{ validator: passwordConfirmValid, trigger: 'blur', required: true }]
      }
    }
  },
  async created() {},
  methods: {
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          const { password } = this.form
          const { system, accountCode } = this.currentItem
          const sha256 = require('js-sha256').sha256
          let newPassword = sha256(password + this.$store.state.privateKey)
          try {
            await this.$api.systemManagement.updateUserPassword({
              newPassword,
              accountCode,
              userSystem: system
            })
            this.$message.success(this.$t('passwordModifiedSuccess'))
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>
