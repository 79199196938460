<template>
  <el-dialog
    v-elDragDialog
    v-loading="loading"
    width="700px"
    :title="isCreated ? `${$t('newadd')} ${parentId ? $t('sourceManagement.son') : ''}${$t('sourceManagement.resources')}` : $t('sourceManagement.editResources')"
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-service"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="120px">
      <el-form-item :label="$t('spaceDetails.resourceName')" prop="resourceName">
        <el-input v-model.trim="form.resourceName" :placeholder="$t('sourceManagement.enterEditResources')" />
      </el-form-item>

      <el-form-item :label="$t('sourceManagement.resourceTitle')" prop="resourceDesc">
        <el-input v-model.trim="form.resourceDesc" :placeholder="$t('sourceManagement.enterResourceTitle')" /> 
      </el-form-item>

      <el-form-item :label="$t('sourceManagement.authorizationName')" prop="permissionName">
        <el-input v-model.trim="form.permissionName" :placeholder="$t('sourceManagement.enterAuthorizationName')" />
      </el-form-item>

      <el-form-item :label="$t('sourceManagement.authorizationURL')" prop="permissionUrl">
        <el-input v-model.trim="form.permissionUrl" :placeholder="$t('sourceManagement.enterAuthorizationURL')" />
      </el-form-item>

      <el-form-item :label="$t('sourceManagement.Icon')" prop="icon">
        <el-input v-model.trim="form.icon" :placeholder="$t('sourceManagement.enterIcon')" />
      </el-form-item>
      <el-form-item :label="$t('sourceManagement.sortValue')" prop="orderNum">
        <el-input-number :min="0" :placeholder="$t('sourceManagement.enterSortValue')" v-model="form.orderNum" />
      </el-form-item>

      <el-form-item :label="$t('spaceDetails.resourceType')" prop="type">
        <el-radio v-model="form.type" :label="1">{{$t('sourceManagement.menu')}}</el-radio>
        <el-radio v-model="form.type" :label="2">{{$t('sourceManagement.button')}}</el-radio>
      </el-form-item>

      <el-form-item :label="$t('sourceManagement.system')" prop="type">
        <el-radio v-model="form.system" :label="1">{{$t('loginPage.sdyPlatform')}}</el-radio>
        <el-radio v-model="form.system" :label="2">{{$t('loginPage.customerPlatform')}}</el-radio>
      </el-form-item>

      <el-form-item v-if="!isCreated" required="" :label="$t('activeDevice.state')" prop="status">
        <el-radio v-model="form.status" :label="0">{{$t('sourceManagement.enable')}}</el-radio>
        <el-radio
          :disabled="currentItem.resourceName === 'systemManagement'"
          v-model="form.status"
          :label="1"
          >{{$t('sourceManagement.stop')}}</el-radio
        >
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{$t('cancel')}}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{$t('save')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['parentId', 'parentSystem'],
  data() {
    return {
      loading: false,
      form: {
        resourceName: '',
        resourceDesc: '',
        permissionName: '',
        permissionUrl: '',
        orderNum: 0,
        icon: '',
        type: 1,
        system: 1,
        status: 0
      },
      rules: {
        resourceName: [{ required: true, message: this.$t('sourceManagement.enterEditResources') }],
        resourceDesc: [{ required: true, message: this.$t('sourceManagement.enterResourceTitle') }],
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const reg = /^(\/\w+)+(\/|\/\*\*|\/\*)?$/
              if (!value) {
                callback(new Error(this.$t('sourceManagement.enterAuthorizationURL1'))) 
              } else {
                if (!res.test(value)) {
                  return callback(new Error(this.$t('sourceManagement.enterAuthorizationURLFormat')))
                } else {
                  callback()
                }
              }
            }
          }
        ]
      }
    }
  },
  created() {
    if (!this.isCreated) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = this.currentItem[key]
      })
    } else {
      if (this.parentSystem) {
        this.form.system = this.parentSystem
      }
    }
  },
  methods: {
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          if (this.parentId) {
            data.parentId = this.parentId
          }
          if (!this.isCreated) {
            data.resourceId = this.currentItem.resourceId
          }

          // data.orderNum = null

          try {
            this.isCreated
              ? await this.$api.systemManagement.resourceAdd(data)
              : await this.$api.systemManagement.resourceUpdate(data)
            this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess') )
            this.$emit('getData')
            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-service {
  ::v-deep .el-form {
    width: 400px;
    margin: 0 auto;
  }
}
</style>
