<template>
  <page>
    <div slot="headerLeft">
      <el-input :placeholder="$t('sourceManagement.userName')" v-model.trim="params.userName" />

      <el-select clearable v-model="params.system" :placeholder="$t('sourceManagement.system')">
        <el-option :label="$t('loginPage.sdyPlatform')" :value="1" />
        <el-option :label="$t('loginPage.customerPlatform')" :value="2" />
      </el-select>

      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
    </div>

    <div class="table-wrap">
      <el-table :data="tableData" border v-loading="loading" height="100%">
        <el-table-column
          show-overflow-tooltip
          prop="userId"
          :label="$t('sourceManagement.userID')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="name"
          :label="$t('sourceManagement.userName')"
        />
        <el-table-column
          width="150"
          show-overflow-tooltip
          prop="clientIp"
          :label="$t('sourceManagement.userIP')"
        />
        <el-table-column show-overflow-tooltip prop="system" :label="$t('sourceManagement.system')">
          <template slot-scope="{ row }">
            <span>{{
              Number(row.system) === 1
                ? $t('loginPage.sdyPlatform')
                : $t('loginPage.customerPlatform')
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="methodRemark"
          :label="$t('sourceManagement.businessDescription')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="methodName"
          :label="$t('sourceManagement.method')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="optReqParam"
          :label="$t('sourceManagement.requestParams')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="optResInfo"
          :label="$t('sourceManagement.response')"
        />
        <el-table-column
          width="200"
          show-overflow-tooltip
          prop="createTime"
          :label="$t('deviceList.createdTime')"
        >
          <template slot-scope="{ row }">
            <span>{{ $utils.formatTime(row.createTime) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'

export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      loading: false,
      visible: false,
      parentId: '',
      currentItem: {},
      tableData: []
    }
  },
  created() {},
  methods: {
    async getData(loading = true) {
      if (!this.params.system) {
        delete this.params.system
      }

      loading ? (this.loading = true) : ''
      try {
        const res = await this.$api.systemManagement.logList(this.params)

        if (res.result) {
          this.tableData = ((res.result && res.result.list) || []).map(item => {
            item.name = item.accountCode || item.username
            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
