<template>
  <page style="overflow-y: auto;">
    <div style="flex: 1;overflow-y: auto;">
      <el-card :body-style="{ padding: '10px' }" class="box-card">
        <div slot="header">
          <span>{{ $t('customerStatistical.electronicScalesRemind') }}</span>
          <el-switch v-model="data1.settingSwitch" :active-value="1" :inactive-value="0" />
        </div>
        <el-row class="info flex">
          <div class="label">
            {{ $t('customerStatistical.electronicScalesReminderFrequency') }}
          </div>
          <el-col :span="22">
            <el-select
              v-model="data1.frequency"
              class="mr20"
              :placeholder="$t('placeholder')"
              @change="changeRemindType"
            >
              <el-option
                :label="$t('customerStatistical.electronicScalesReminderFixedFrequency')"
                :value="1"
              />
              <el-option
                :label="$t('customerStatistical.electronicScalesReminderDesignateFrequency')"
                :value="2"
              />
            </el-select>
            <el-select
              v-model="data1.settingThreshold"
              :placeholder="$t('placeholder')"
              v-if="data1.frequency == 1"
            >
              <el-option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                :label="
                  option.label + $t('customerStatistical.electronicScalesReminderFrequencyUnit')
                "
              />
            </el-select>
            <div v-else>
              <el-row type="flex" class="info">
                <el-col :span="7">
                  <div>
                    <span class="mr20">
                      {{ $t('customerStatistical.electronicScalesReminderTime') }}1
                    </span>
                    <el-time-picker
                      v-model="times[0]"
                      value-format="HH:mm:ss"
                      :placeholder="$t('placeholder')"
                    />
                  </div>
                </el-col>
                <el-col :span="7">
                  <div>
                    <span class="mr20">
                      {{ $t('customerStatistical.electronicScalesReminderTime') }}2
                    </span>
                    <el-time-picker
                      v-model="times[1]"
                      value-format="HH:mm:ss"
                      :placeholder="$t('placeholder')"
                    />
                  </div>
                </el-col>
                <el-col :span="7">
                  <div>
                    <span class="mr20">
                      {{ $t('customerStatistical.electronicScalesReminderTime') }}3
                    </span>
                    <el-time-picker
                      v-model="times[2]"
                      value-format="HH:mm:ss"
                      :placeholder="$t('placeholder')"
                    />
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" class="info">
                <el-col :span="7">
                  <div>
                    <span class="mr20">
                      {{ $t('customerStatistical.electronicScalesReminderTime') }}4
                    </span>
                    <el-time-picker
                      v-model="times[3]"
                      value-format="HH:mm:ss"
                      :placeholder="$t('placeholder')"
                    />
                  </div>
                </el-col>
                <el-col :span="7">
                  <div>
                    <span class="mr20">
                      {{ $t('customerStatistical.electronicScalesReminderTime') }}5
                    </span>
                    <el-time-picker
                      v-model="times[4]"
                      value-format="HH:mm:ss"
                      :placeholder="$t('placeholder')"
                    />
                  </div>
                </el-col>
                <el-col :span="7">
                  <div>
                    <span class="mr20">
                      {{ $t('customerStatistical.electronicScalesReminderTime') }}6
                    </span>
                    <el-time-picker
                      v-model="times[5]"
                      value-format="HH:mm:ss"
                      :placeholder="$t('placeholder')"
                    />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row class="info flex">
          <div class="label">
            {{ $t('customerStatistical.electronicScalesReminderDevice') }}
          </div>
          <el-col :span="22">
            <el-select v-model="deviceSelectType" :placeholder="$t('placeholder')" class="mr20">
              <el-option
                :label="$t('customerStatistical.electronicScalesReminderDeviceAll')"
                :value="1"
              />
              <el-option
                :label="$t('customerStatistical.electronicScalesReminderDeviceDesignate')"
                :value="2"
              />
            </el-select>
            <el-button
              type="primary"
              size="small"
              v-if="deviceSelectType == 2"
              @click="visible = true"
            >
              {{ $t('select') }}
            </el-button>
            <div class="device-list" v-if="deviceSelectType == 2">
              <el-table
                :data="bindDevices"
                border
                v-loading="loading"
                style="min-height: 300px;"
                height="100%"
              >
                <el-table-column prop="sn" :label="$t('electronicScalesReminder.sn')" />
                <el-table-column
                  prop="modelName"
                  :label="$t('electronicScalesReminder.deviceModel')"
                />
                <el-table-column prop="groupName" :label="$t('electronicScalesReminder.group')" />
                <el-table-column prop="store" :label="$t('electronicScalesReminder.store')" />
                <el-table-column :label="$t('operation')">
                  <template slot-scope="{ row }">
                    <el-button type="text" @click="del(row)">
                      {{ $t('delete') }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </el-card>
      <el-card :body-style="{ padding: '10px' }" class="box-card">
        <div slot="header">
          <span>{{ $t('customerStatistical.peripheralSelfTest') }}</span>
          <el-switch v-model="data2.settingSwitch" :active-value="1" :inactive-value="0" />
        </div>
        <el-row class="info flex">
          <div class="label">{{ $t('customerStatistical.peripheralSelfTestInfo') }}</div>
          <el-col :span="22">
            <el-input-number
              v-model="data2.settingThreshold"
              :min="0"
              :placeholder="$t('pleaseEnter')"
              style="width: 200px"
              clearable
            />
            <div class="tips">
              {{ $t('customerStatistical.peripheralSelfTestTips') }}
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>

    <div style="width: 100%; text-align: center">
      <el-button type="warning" style="width: 200px" @click="submit">{{ $t('update') }}</el-button>
    </div>
    <device-modal :visible.sync="visible" @selected="changeSelectedDevice" v-if="visible" />
  </page>
</template>

<script>
import deviceModal from './components/deviceModal'
export default {
  components: {
    deviceModal
  },
  data() {
    return {
      loading: false,
      visible: false,
      remindSwitch: false,
      showHourSelect: true,
      deviceSelectType: 1,
      bindDevices: [],
      selectedDevices: [],
      data1: {
        id: '',
        settingType: 1,
        settingSwitch: 0,
        settingDevice: '',
        settingThreshold: '',
        frequency: 1
      },
      data2: {
        id: '',
        settingType: 2,
        settingSwitch: 0,
        settingThreshold: ''
      },
      options: [
        {
          value: '0.5',
          label: '0.5 '
        },
        {
          value: '1.0',
          label: '1.0 '
        },
        {
          value: '1.5',
          label: '1.5 '
        },
        {
          value: '2.0',
          label: '2.0 '
        },
        {
          value: '3.0',
          label: '3.0 '
        }
      ],
      times: ['', '', '', '', '', '']
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      try {
        const res = await this.$api.systemManagement.settingConfig()
        if (res.result) {
          res.result.forEach((item, index) => {
            if (item.settingType == 1) {
              // 提醒
              this.data1.id = item.id
              this.data1.settingSwitch = item.settingSwitch
              this.data1.frequency = item.frequency
              if (item.frequency == 1) {
                this.data1.settingThreshold = item.settingThreshold
              } else {
                this.times = item.times || ['', '', '', '', '', '']
              }
              this.bindDevices = item.deviceList || []
              if (this.bindDevices.length == 0) {
                this.deviceSelectType = 1
              } else {
                this.deviceSelectType = 2
              }
            }
            if (item.settingType == 2) {
              this.data2.id = item.id
              this.data2.settingSwitch = item.settingSwitch
              this.data2.settingThreshold = item.settingThreshold
            }
          })
        } else {
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    changeRemindType(value) {
      this.showHourSelect = value == 1
    },
    del(row) {
      this.bindDevices = this.bindDevices.filter(item => item.sn != row.sn)
    },
    changeSelectedDevice(selected) {
      let sns = this.bindDevices.map(item => item.sn) || []
      const devices = selected.filter(item => {
        return sns.indexOf(item.sn) == -1
      })
      this.bindDevices.push(...devices)
    },
    async submit() {
      if (this.data1.frequency == 2) {
        let times = this.times.filter(item => item != '')
        if (times.length == 0) {
          this.$message.error(this.$t('customerStatistical.timeInput'))
          return
        }
        if (this.hasDuplicates(times)) {
          this.$message.error(this.$t('customerStatistical.timeRepeats'))
          return
        }
        this.data1.settingThreshold = this.times.join(',')
      } else {
        if (!this.data1.settingThreshold) {
          this.$message.error(this.$t('customerStatistical.timeFrequency'))
          return
        }
      }
      if (this.deviceSelectType == 1) {
        this.data1.settingDevice = ''
      } else {
        if (this.bindDevices.length == 0) {
          this.$message.error(this.$t('customerStatistical.oneDeviceAtleast'))
          return
        }
        this.data1.settingDevice = this.bindDevices.map(item => item.sn).join(',')
      }
      const data = {
        deviceSettingConfigReqVos: [this.data1, this.data2]
      }
      const res = await this.$api.systemManagement.settingConfigUpdate(data)
      await this.getData()
      this.$message.success(this.$t('updateSuccess'))
    },
    hasDuplicates(array) {
      return new Set(array).size !== array.length
    }
  }
}
</script>

<style lang="scss" scoped>
.el-card__header > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}
.box-card {
  margin: 4px;
  overflow: visible;

  .info {
    padding: 10px;
    .label {
      margin: 5px 10px 0 0;
      min-width: 100px;
    }
  }
  .device-list {
    margin-top: 10px;
  }
  .mr20 {
    margin-right: 10px;
  }
  .tips {
    font-size: 14px;
    color: #888d99;
    padding-top: 10px;
  }
}
</style>
