<template>
  <page>
    <div slot="headerLeft">
      <el-input
        :placeholder="$t('serviceAccountManagement.loginAccount')"
        v-model.trim="params.accountCode"
        @keyup.enter.native="replaceData"
        clearable
      />
      <el-input
        :placeholder="$t('serviceAccountManagement.name')"
        v-model.trim="params.name"
        @keyup.enter.native="replaceData"
        clearable
      />
      <el-button type="primary" plain @click="replaceData">{{ $t('query') }}</el-button>
      <el-button v-show="multipleSelection.length" type="danger" plain @click="delHandler(true)">{{
        $t('delete')
      }}</el-button>
    </div>
    <div slot="headerRight">
      <el-button
        v-if="btnShow && btnShow.add"
        type="primary"
        @click="
          visible = true
          currentItem = null
        "
        icon="el-icon-plus"
        >{{ $t('newadd') }}</el-button
      >
    </div>

    <div class="table-wrap">
      <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        border
        v-loading="loading"
        height="100%"
      >
        <el-table-column v-if="btnShow && btnShow.delete" type="selection" width="55" />
        <el-table-column
          show-overflow-tooltip
          prop="accountCode"
          :label="$t('serviceAccountManagement.loginAccount')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="name"
          :label="$t('serviceAccountManagement.name')"
        />
        <el-table-column
          show-overflow-tooltip
          prop="roleName"
          :label="$t('serviceAccountManagement.role')"
        />
        <el-table-column width="100" :label="$t('serviceAccountManagement.accountStatus')">
          <template slot-scope="{ row }">
            <el-switch v-model="row.status" @change="statusUpdate(row)" />
          </template>
        </el-table-column>

        <el-table-column width="200" :label="$t('operation')">
          <template slot-scope="{ row }">
            <div class="operateRow">
              <el-button
                v-if="btnShow && btnShow.changePassword"
                type="text"
                @click="
                  currentItem = row
                  modifyPwdVisible = true
                "
                >{{ $t('changePassword') }}</el-button
              >
              <el-button v-if="btnShow && btnShow.edit" type="text" @click="editHandler(row)">{{
                $t('edit')
              }}</el-button>
              <el-button
                v-if="btnShow && btnShow.delete"
                type="text"
                @click="delHandler(false, row)"
                >{{ $t('delete') }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.currentPage"
      @change="getData"
    />
    <add-account-modal
      @getData="replaceData"
      :roleList="roleList"
      :currentItem="currentItem"
      :visible.sync="visible"
      v-if="visible"
    />
    <ModifyPwdModal
      @getData="replaceData"
      :currentItem="currentItem"
      :visible.sync="modifyPwdVisible"
      v-if="modifyPwdVisible"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import AddAccountModal from './components/AddAccountModal'
import ModifyPwdModal from '../serviceAccountManagement/components/ModifyPwdModal'
export default {
  mixins: [watchParamsGetData],
  components: {
    AddAccountModal,
    ModifyPwdModal
  },
  data() {
    return {
      firstIn: true,
      visible: false,
      isChild: false,
      modifyPwdVisible: false,
      currentItem: {},
      tableData: [],
      multipleSelection: []
    }
  },

  methods: {
    async getSelectData() {
      let res = await this.$api.systemManagement.roleList({ pageSize: -1, system: 1 })

      if (res.result) {
        this.roleList = res.result.list
          .filter(_ => _.id !== Number(sessionStorage.getItem('userId')))
          .map(item => {
            return {
              label: item.roleName,
              value: item.roleId
            }
          })
      }
    },
    async getData(loading = true) {
      loading ? (this.loading = true) : ''
      if (this.firstIn) {
        try {
          await this.getSelectData()
        } catch (e) {
          console.log(e)
        } finally {
          this.firstIn = false
          this.renderData()
        }
      } else {
        this.renderData()
      }
    },
    async renderData() {
      try {
        const data = this.params
        data.accountType = 1
        const res = await this.$api.systemManagement.userList(data)

        if (res.result) {
          this.tableData = res.result.list.map(item => {
            // item.accountType = this.getAccountType(item.accountType)
            item.status = !item.status

            if (item.roleList && item.roleList.length) {
              item.roleName = item.roleList[0] && item.roleList[0].roleName
              item.roleId = item.roleList[0] && item.roleList[0].roleId
            }

            return item
          })
          this.total = res.result.total
        } else {
          this.tableData = []
          this.total = 0
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.firstIn = false
        this.loading = false
      }
    },

    getAccountType(type) {
      let accountTypeTxt = ''

      switch (type) {
        case 0:
          accountTypeTxt = this.$t('serviceAccountManagement.admin')
          break
        case 1:
          accountTypeTxt = this.$t('serviceAccountManagement.operator')
          break
        case 2:
          accountTypeTxt = this.$t('serviceAccountManagement.customerAdmin')
          break
        case 3:
          accountTypeTxt = this.$t('serviceAccountManagement.customerOperator')
          break
      }
      return accountTypeTxt
    },

    handleSelectionChange(val) {
      this.multipleSelection = val.map(item => item.id)
    },

    editHandler(row, isChild) {
      this.isChild = isChild || false
      this.currentItem = row
      this.visible = true
    },

    async statusUpdate(row) {
      this.loading = true

      try {
        await this.$api.systemManagement.userUpdate({ id: row.id, status: row.status ? 0 : 1 })
      } catch (e) {
        row.status = !row.status
      } finally {
        this.loading = false
      }
    },

    delHandler(delMore, row) {
      this.$confirm(this.$t('serviceAccountManagement.deleteThisAccount'), this.$t('tips'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel')
      })
        .then(async () => {
          this.loading = true

          try {
            await this.$api.systemManagement.userDelete(delMore ? this.multipleSelection : [row.id])
            this.$message.success(this.$t('deleteSuccess'))
          } catch (e) {
            console.log(e)
          } finally {
            this.loading = false
          }

          this.getData(false)
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
.expandWrap {
  ::v-deep .el-table th,
  .el-table tr {
    background-color: #f5f7fa;
  }
}
</style>
