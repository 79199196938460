<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="700px"
    :title="
      isCreated
        ? $t('serviceAccountManagement.newAccount')
        : $t('serviceAccountManagement.editAccount')
    "
    :close-on-click-modal="false"
    :visible.sync="show"
    class="add-service"
  >
    <el-form :model="form" :rules="rules" size="small" ref="form" label-width="100px">
      <el-form-item :label="$t('serviceAccountManagement.name')" prop="name">
        <el-input
          v-model.trim="form.name"
          :placeholder="$t('serviceAccountManagement.enterName')"
        />
      </el-form-item>

      <el-form-item :label="$t('serviceAccountManagement.role')" prop="roleId">
        <el-select v-model="form.roleId" :placeholder="$t('serviceAccountManagement.selectRole')">
          <el-option
            v-for="item in roleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('serviceAccountManagement.emailPhone')" prop="phone">
        <el-input
          v-model.trim="form.phone"
          :placeholder="$t('serviceAccountManagement.enteremailPhone')"
        />
      </el-form-item>
      <el-form-item
        :label="$t('serviceAccountManagement.loginAccount')"
        :prop="isCreated ? 'accountCode' : ''"
      >
        <el-input
          :disabled="!isCreated"
          v-model.trim="form.accountCode"
          :placeholder="$t('serviceAccountManagement.enterLoginAccount')"
        />
      </el-form-item>

      <div v-if="isCreated">
        <el-form-item :label="$t('serviceAccountManagement.password')" prop="password">
          <el-input
            class="selfInput"
            :type="invisibleOne ? 'password' : 'text'"
            v-model.trim="form.password"
          >
            <icon-svg
              v-if="form.password"
              :icon-class="invisibleOne ? 'iconbukejian' : 'iconkejian'"
              slot="suffix"
              @click="invisibleOne = !invisibleOne"
            />
          </el-input>
        </el-form-item>

        <el-form-item
          :label="$t('serviceAccountManagement.confirmPassword')"
          prop="confirmPassword"
        >
          <el-input
            class="selfInput"
            @keyup.native.enter="submitForm"
            :type="invisibleTwo ? 'password' : 'text'"
            v-model.trim="form.confirmPassword"
          >
            <icon-svg
              v-if="form.confirmPassword"
              :icon-class="invisibleTwo ? 'iconbukejian' : 'iconkejian'"
              slot="suffix"
              @click="invisibleTwo = !invisibleTwo"
            />
          </el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{ $t('save') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['isChild', 'serviceList', 'roleList'],
  data() {
    const passwordValid = (rule, value, callback) => {
      const pdTest = /^(?=.*[~!@#$%^&*()_?])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/

      if (value === '') {
        callback(new Error(this.$t('loginPage.passwordPlaceholder')))
      } else {
        if (value === this.form.accountCode) {
          callback(new Error(this.$t('passwordDifferentAccount')))
        } else if (!pdTest.test(value)) {
          callback(new Error(this.$t('passwordRules')))
        } else {
          callback()
        }
      }
    }

    const passwordConfirmValid = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('serviceAccountManagement.enterConfirmPassword')))
      } else if (value !== this.form.password) {
        callback(new Error(this.$t('twicePasswordDifferent')))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      invisibleOne: true,
      invisibleTwo: true,
      form: {
        name: '',
        roleId: '',
        phone: '',
        accountCode: '',
        password: '',
        confirmPassword: '',
        accountType: 3
      },

      rules: {
        name: [{ required: true, message: this.$t('serviceAccountManagement.enterName') }],
        roleId: [{ required: true, message: this.$t('serviceAccountManagement.selectRole') }],
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t('serviceAccountManagement.enteremailPhone')))
              } else {
                console.log(!value.includes('@') && !this.$utils.isPhone(value))
                if (!value.includes('@')) {
                  if (!this.$utils.isPhone(value)) {
                    return callback(
                      new Error(this.$t('serviceAccountManagement.correctMobileFormat'))
                    )
                  }
                  callback()
                } else {
                  callback()
                }
              }
            }
          }
        ],
        accountCode: [
          { required: true, message: this.$t('serviceAccountManagement.enterLoginAccount') }
        ],
        password: [{ validator: passwordValid, trigger: 'blur', required: true }],
        confirmPassword: [{ validator: passwordConfirmValid, trigger: 'blur', required: true }]
      }
    }
  },
  created() {
    if (!this.isCreated) {
      Object.keys(this.form).forEach(key => {
        if (key === 'roleId') {
          if (this.roleList.map(_ => _.value).includes(this.currentItem[key])) {
            this.form.roleId = this.currentItem[key]
          }
        } else {
          this.form[key] = this.currentItem[key]
        }
      })
    }
  },
  methods: {
    submitForm(data) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.loading = true
          const { confirmPassword, ...sendData } = data
          const sha256 = require('js-sha256').sha256

          if (!this.isCreated) {
            sendData.id = this.currentItem.id
          }

          sendData.password = sha256(sendData.password + this.$store.state.privateKey)

          if (!this.isCreated) {
            sendData.id = this.currentItem.id
          }

          sendData.status = 0
          sendData.accountType = 1
          sendData.system = 1
          try {
            this.isCreated
              ? await this.$api.systemManagement.userAdd(sendData)
              : await this.$api.systemManagement.userUpdate(sendData)
            this.$message.success(this.isCreated ? this.$t('newSuccess') : this.$t('editSuccess'))
            this.$emit('getData')
            this.show = false
          } catch (e) {
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-service {
  ::v-deep .el-form {
    width: 400px;
    margin: 0 auto;
    .el-input {
      width: 250px;
    }
  }
  ::v-deep .el-form-item.is-error {
    margin-bottom: 32px;
  }
}
</style>
