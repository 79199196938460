<template>
  <el-dialog
    v-loading="loading"
    v-elDragDialog
    width="700px"
    :title="$t('serviceManagement.addAssociatedCustomers')"
    :close-on-click-modal="false"
    append-to-body
    :visible.sync="show"
    class="add-realte-modal"
  >
    <el-form :model="form" size="small" ref="form" label-width="150px">
      <el-form-item :label="$t('serviceManagement.customerName')">
        <el-autocomplete
          v-model="form.name"
          :fetch-suggestions="querySearchAsync"
          :placeholder="$t('serviceManagement.enterCustomerName')"
          @select="handleSelect"
        />
      </el-form-item>

      <el-form-item :label="$t('serviceManagement.customerID')">
        <el-input v-model.trim="form.spId" :placeholder="$t('serviceManagement.enterContact')" />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{$t('cancel')}}</el-button>
      <el-button type="primary" @click="submitForm(form)">{{$t('save')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  components: {},
  props: ['spId'],
  data() {
    return {
      lists: [],
      loading: false,
      form: {
        name: '',
        spId: ''
      },
      rules: {
        name: [{ required: true, message: this.$t('serviceManagement.enterCustomerName') }],
        spId: [{ required: true, message: this.$t('serviceManagement.enterCustomerID')}]
      }
    }
  },
  created() {
    this.initData()
  },
  methods: {
    async initData() {
      try {
        const res = await this.$api.systemManagement.spQuery({ name: '' })
        if (res && res.result) {
          const lists = res.result.map(item => {
            return {
              value: item.name,
              spId: item.spId
            }
          })

          this.lists = lists
        }
      } catch (e) {
        console.log(e)
      }
    },
    querySearchAsync(queryString, cb) {
      const lists = this.lists
      const results = queryString ? lists.filter(this.createStateFilter(queryString)) : lists

      cb(results)
    },
    createStateFilter(queryString) {
      return state => {
        return state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    handleSelect(item) {
      this.form.spId = item.spId
    },
    async submitForm(data) {
      console.log(this.spId)

      this.loading = true

      try {
        await this.$api.systemManagement.spRelationAdd({
          relationSpId: data.spId,
          spId: this.spId
        })
        this.$message.success(this.$t('saveSuccess'))
        this.show = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
      // this.$refs.form.validate(async valid => {
      //   if (valid) {
      //     this.loading = true

      //     try {
      //       await this.$api.systemManagement.spRelationAdd({
      //         relationSpId: data.spId,
      //         spId: this.currentItem.spId
      //       })
      //       this.$message.success('保存成功！')
      //       this.$emit('getData')
      //       this.show = false
      //     } catch (e) {
      //     } finally {
      //       this.loading = false
      //     }
      //   }
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-realte-modal {
  ::v-deep .el-form {
    width: 400px;
    margin: 0 auto;
  }

  ::v-deep .el-autocomplete {
    width: 100%;
  }
}
</style>
